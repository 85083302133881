import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import MainContainer from './components/MainContainer';
//import Footer from './components/Footer';
import './App.css';

function App() {

    const [files, setFiles] = React.useState([])
    return (
        <Router>
            <div className="flex flex-col min-h-screen bg-bodyBackground text-textPrimary">
                <div className="fixed w-full z-10">
                    <NavBar />
                </div>
                <div className="flex w-full justify-center my-16">
                    <div className='w-[900px]'>
                        <Routes>
                            <Route path="/" element={<MainContainer />} />
                        </Routes>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        </Router>
    );
}

export default App;
