
import CheckIconSvg from '../../assets/check.svg';

// Iconos de check y X
const CheckIcon = () => <img width={30} alt='checkSvg' src={CheckIconSvg} />
const ErrorIcon = () => <span style={{ color: 'red' }}>❌</span>;

function InputCustom({maxLength, regexChange, register, errors, isValid, keyInput, label, placeholder, top, onChange}) {

    const handleBeforeInput = (e) => {
        if (regexChange && !regexChange.test(e.data)) {
          e.preventDefault();
        }
    };

    return (
        <div className='w-full' style={{ position: 'relative', marginBottom: '1rem' }}>
            <label className='text-sm' htmlFor={keyInput}>{label}</label>
            <input
                id={keyInput}
                name={keyInput}
                type="text"
                {...register(keyInput)}
                style={{ width: '100%', paddingRight: '24px' }}
                className={`${errors[keyInput] ? 'form__input--error' : isValid && !errors[keyInput] ? 'form__input--success' : 'form__input'} `}
                placeholder={placeholder}
                onChange={onChange ? onChange : register(keyInput).onChange}
                onBeforeInput={handleBeforeInput}
                maxLength={maxLength ? maxLength : 50}
            />
            <span className={`${label ? top ? top : 'top-[45px]' :'top-[23px]'} `} style={{
                position: 'absolute',
                right: '8px',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
            }}>
                {errors[keyInput] ? <ErrorIcon /> : isValid && !errors[keyInput] && <CheckIcon />}
            </span>
            {errors[keyInput] && <small className='text--error'>*{errors[keyInput].message}</small>}
        </div>
    )
}

export default InputCustom;