
const apiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  services: {
    createLegalRepresentative: '/api/v1/legalrepresentative',
    createPublicWorkers: '/api/v1/publicworkers',
    createUser: '/api/v1/users',
  },
};

export default apiConfig;

