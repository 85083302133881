import upload from '../../assets/upload.svg'
import InputCustom from '../../shared/components/InputCustom';
import React, { useState, useEffect } from 'react';
import apiConfig from '../../config/apiConfig';
import HttpClient from '../../api/httpClient';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import PreviewDocument from '../../shared/components/PreviewDocument';
import Footer from '../Footer';

const schema = yup.object({
    fullName: yup.string().required('El Nombre es requerido.').matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "No se admiten caracteres especiales."),
    secondName: yup.string().required('El Apellido Paterno es requerido.').matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "No se admiten caracteres especiales."),
    thirdName: yup.string().required('El Apellido Materno es requerido.').matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "No se admiten caracteres especiales."),
    phone: yup.string().required('El Teléfono es requerido.').matches(/^[1-9]\d*$/, "Solo se admiten números."),
    email: yup.string().required('El Correo Electrónico es requerido.').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "El Correo Electrónico no es válido."),
    taxRefResponsible: yup.string().required('El RFC es requerido.').max(13, "El máximo de caracteres es 13.").matches(/^([A-ZÑ&]{3,4}) ?(\d{2})(\d{2})(\d{2}) ?([A-Z\d]{2})([A\d])$/, "El RFC no es válido."),
    //idJob: yup.string().required('El Cargo es requerido.').oneOf(['Gerente', 'Subgerente'], 'Selecciona un Cargo válido.'),
    idJob: yup.string().required('El Cargo es requerido.').matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "No se admiten caracteres especiales."),
    motivo: yup.string().when('$action', {
        is: 'down',
        then: (schema) => schema.required('El motivo es requerido cuando se da de baja al funcionario.'),
        otherwise: (schema) => schema.notRequired(),
    }),
    permiso1: yup.boolean().optional(),
    permiso2: yup.boolean().optional(),
    permiso3: yup.boolean().optional(),
    permiso4: yup.boolean().optional(),
    permiso5: yup.boolean().optional(),
    permiso6: yup.boolean().optional(),
    permiso7: yup.boolean().optional(),
    confirmation: yup.array().min(1, 'Debe seleccionar al menos un reporte')
  });

const apiClient = new HttpClient(apiConfig.baseURL);

function PublicWorkers({onSubmit, toastNotifier}) {

    const [action, setAction] = useState('up');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid, isSubmitted, touchedFields },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        context: { action }
    });
    
    useEffect(() => {
        reset({}, { keepValues: true, keepErrors: true, keepDirty: true, keepIsValid: true });
    }, [action, reset]);
    

    const [files, setFiles] = useState({
        cardIdB64UserFrontal: {value: null, name: '', valid: '', message: ''},
        cardIdB64UserReverse: {value: null, name: '', valid: '', message: ''},
        cardIdB64AuthorizingFrontal: {value: null, name: '', valid: '', message: ''},
        cardIdB64AuthorizingReverse: {value: null, name: '', valid: '', message: ''},
    });

    const [loading, setLoading] = useState(false);
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file && file instanceof Blob) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result.split(',')[1]);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            } else {
                reject(new Error("El archivo proporcionado no es válido o no es del tipo Blob"));
            }
        });
    };

    const handleFileChange = async (e, field) => {
        const file = e.target.files[0];

        if (file && file.size > 50 * 1024 * 1024) {
            setFiles({
                ...files,
                [field]: {value: null, valid: 'error', message: 'El archivo excede el tamaño máximo permitido de 50 MB.'},
            });
        }
        try {
            const base64File = await convertFileToBase64(file);
            setFiles({
                ...files,
                [field]: {value: base64File, name: file.name, valid: 'success'},
            });
        } catch (error) {
            console.error("Error al convertir el archivo:", error);
        }
    };

    const onSubmitForm = async (e) => {
        setLoading(true);

        try {
            const ids = Object.entries(e)
            .filter(([key, value]) => key.startsWith('permiso') && value)
            .map(([key]) => parseInt(key.replace('permiso', ''), 10));

            const jsonObject = {
                id: 0,
                actionType: action === 'up' ? 1 : 2,
                fullName: e.fullName,
                secondName: e.secondName,
                thirdName: e.thirdName,
                idJob : 0,
                jobDesc: e.idJob,
                phone: e.phone,
                email: e.email,
                taxRefResponsible: e.taxRefResponsible,
                permisos: ids,
                cardIdB64UserFrontal: files.cardIdB64UserFrontal.value,
                cardIdB64UserReverse: files.cardIdB64UserReverse.value,
                rejection: action === 'down' ? e.motivo : '',
                cardIdB64AuthorizingFrontal: action === 'up' ? 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgUBA5c0SdAAAAAASUVORK5CYII=' : files.cardIdB64AuthorizingFrontal.value,
                cardIdB64AuthorizingReverse: action === 'up' ? 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgUBA5c0SdAAAAAASUVORK5CYII=' : files.cardIdB64AuthorizingReverse.value,
            };
            apiClient.setAuthToken(sessionStorage.getItem('token'));
            await apiClient.post(apiConfig.services.createPublicWorkers, jsonObject);
            onSubmit("Formulario enviado con éxito.", { type: 'success', message: "Formulario enviado con éxito." });
        } catch (error) {
            let message = "Existió un problema al enviar el formulario.";

            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    message = "Tu solicitud ha expirado.";
                } else {
                    const statusMessage = error.response.data?.statusResponse?.message || error.response.data?.message || message;
                    message = `(Error ${status}) ${statusMessage}`;
                }
            } else if (error.request) {
                message = "No hubo respuesta del servidor. Intenta nuevamente.";
            }
    
            toastNotifier({ type: 'error', message });
        } finally {
            setLoading(false);
        }
    };

    const handleChangeOnlyNumber = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
          setValue('phone', value, { shouldValidate: true });
        }
    };

    const handleChangeUppercase = (e) => {
        const value = e.target.value.toUpperCase();
        setValue('taxRefResponsible', value, { shouldValidate: true });
    };

    return (
        <>
            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div className='container--form'>
                <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off" className='form--shadow'>
                    <div className='header--title'>
                        <label className='header__text'>Alta y baja de funcionario</label>
                    </div>

                    <div className='mt-2'>
                        <label className='label--primary' htmlFor="action">Acción a realizar:</label>
                        <div className='flex-box--gap'>
                            <div>
                                <input type="radio" name="actiontype" defaultChecked value="up" id="" onChange={() => setAction('up')}/>
                                <span className='text-sm pl-2'>Alta</span>
                            </div>
                            <div>
                                <input  type="radio" value="down" name="actiontype" id="" onChange={() => setAction('down')} />
                                <span className='text-sm pl-2'>Baja</span>
                            </div>
                        </div>
                    </div>

                    <div className="content-separator"></div>

                    <div className='panel--dashed'>
                        <label className='label--primary'>Carga de documentos : </label>
                        <div className='flex-container--column'>
                            <label className='text-sm' htmlFor="user">1. Identificación oficial del representante legal : <PreviewDocument isIne={true}/></label>
                            <div className='grid-container--gapped'>
                                <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray 
                                border 
                                ${isSubmitted && !files.cardIdB64UserFrontal.value ? 'border-red' : 'border-lightGray'}
                                rounded-lg p-1 cursor-pointer`}
                                htmlFor="fileUF">

                                    <span className='text--label--primary'>Elegir archivo</span>
                                    <img width={20} src={upload} alt="" />
                                    <span className='text--label--primary--bold'>Frente</span>
                                    <input 
                                        onChange={(e) => handleFileChange(e, 'cardIdB64UserFrontal')}
                                        type="file" 
                                        id='fileUF'
                                        className='hidden'
                                        accept=".pdf,image/jpeg,image/png"
                                    />
                                    <small className='italic text--label--primary'>{ files.cardIdB64UserFrontal.name }</small>
                                </label>
                                {isSubmitted && !files.cardIdB64UserFrontal.value ? <small className='text--error'>*El archivo es requerido.</small> : null}

                                <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray 
                                border
                                ${isSubmitted && !files.cardIdB64UserReverse.value ? 'border-red' : 'border-lightGray'}
                                rounded-lg p-1 cursor-pointer`}
                                    htmlFor="fileUR">
                                    <span className='text--label--primary'>Elegir archivo</span>
                                    <img width={20} src={upload} alt="" />
                                    <span className='text--label--primary--bold'>Reverso</span>
                                    <input 
                                        onChange={(e) => handleFileChange(e, 'cardIdB64UserReverse')}
                                        type="file" 
                                        id='fileUR'
                                        className='hidden'
                                        accept=".pdf,image/jpeg,image/png"
                                    />
                                    <small className='italic text--label--primary'>{ files.cardIdB64UserReverse.name }</small>
                                </label>
                                {isSubmitted && !files.cardIdB64UserReverse.value ? <small className='text--error'>*El archivo es requerido.</small> : null}
                            </div>

                        </div>

                        {action === 'down' && (
                            <div className='flex-container--column mt-4'>
                                <label className='text-sm' htmlFor="user">2. Identificación oficial del funcionario : <PreviewDocument isIne={true}/></label>
                                <div className='grid-container--gapped'>
                                    <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray 
                                    border
                                    ${isSubmitted && !files.cardIdB64AuthorizingFrontal.value ? 'border-red' : 'border-lightGray'}
                                    rounded-lg p-1 cursor-pointer`}
                                    htmlFor="fileAF">

                                        <span className='text--label--primary'>Elegir archivo</span>
                                        <img width={20} src={upload} alt="" />
                                        <span className='text--label--primary--bold'>Frente</span>
                                        <input 
                                            onChange={(e) => handleFileChange(e, 'cardIdB64AuthorizingFrontal')} 
                                            type="file" 
                                            id='fileAF'
                                            className='hidden'
                                            accept=".pdf,image/jpeg,image/png"
                                        />
                                        <small className='italic text--label--primary'>{ files.cardIdB64AuthorizingFrontal.name }</small>
                                    </label>
                                    {isSubmitted && !files.cardIdB64AuthorizingFrontal.value ? <small className='text--error'>*El archivo es requerido.</small> : null}

                                    <label className={`mt-2 flex-container--column justify-center items-center gap-x-2 w-full bg-lightGray 
                                    border
                                    ${isSubmitted && !files.cardIdB64AuthorizingReverse.value ? 'border-red' : 'border-lightGray'}
                                    rounded-lg p-1 cursor-pointer`}
                                        htmlFor="fileAR">
                                        <span className='text--label--primary'>Elegir archivo</span>
                                        <img width={20} src={upload} alt="" />
                                        <span className='text--label--primary--bold'>Reverso</span>
                                        <input 
                                            onChange={(e) => handleFileChange(e, 'cardIdB64AuthorizingReverse')} 
                                            type="file" 
                                            id='fileAR'
                                            className='hidden'
                                            accept=".pdf,image/jpeg,image/png"
                                        />
                                        <small className='italic text--label--primary'>{ files.cardIdB64AuthorizingReverse.name }</small>
                                    </label>
                                    {isSubmitted && !files.cardIdB64AuthorizingReverse.value ? <small className='text--error'>*El archivo es requerido.</small> : null}
                                </div>
                            </div>      
                        )}

                        {action === 'down' && (
                            <InputCustom maxLength={500} register={register} errors={errors} isValid={touchedFields.motivo} keyInput={'motivo'} label={'*Motivo:'} placeholder={'Motivo de la baja'} />
                        )}
                        
                    </div>

                    <div className='border border-dashed rounded-lg p-2 mt-4'>

                        <label className='label--primary'>Datos del funcionario facultado : </label>
                        
                        <div className='grid grid-cols-1 mt-2'>
                            <InputCustom register={register} errors={errors} isValid={touchedFields.fullName} keyInput={'fullName'} label={'*Nombre(s):'} placeholder={'Nombre(s)'} />

                            <InputCustom register={register} errors={errors} isValid={touchedFields.secondName} keyInput={'secondName'} label={'*Apellido Paterno:'} placeholder={'Paterno'} />

                            <InputCustom register={register} errors={errors} isValid={touchedFields.thirdName} keyInput={'thirdName'} label={'*Apellido Materno:'} placeholder={'Materno'} />

                            <InputCustom register={register} errors={errors} isValid={touchedFields.idJob} keyInput={'idJob'} label={'*Cargo:'} placeholder={'Cargo'} />

                            <InputCustom maxLength={10} regexChange={/^\d*$/} onChange={handleChangeOnlyNumber} register={register} errors={errors} isValid={touchedFields.phone} keyInput={'phone'} label={'*Teléfono:'} placeholder={'Teléfono'} />

                            <InputCustom register={register} errors={errors} isValid={touchedFields.email} keyInput={'email'} label={'*Correo Electrónico:'} placeholder={'Correo Electrónico'} />

                            <InputCustom maxLength={13} onChange={handleChangeUppercase} register={register} errors={errors} isValid={touchedFields.taxRefResponsible} keyInput={'taxRefResponsible'} label={'*RFC:'} placeholder={'RFC'} />
                        </div>
                    </div>

                    <div className='border border-dashed rounded-lg p-2 mt-4'>
                        <label className='text-[#042135] font-semibold'>Permisos : </label>
                        <div className="grid grid-cols-1 gap-2 mt-2 px-2">
                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso1')} type="checkbox" className="w-4 h-4" />
                                </div>
                                <label className="text-xs">
                                    MODIFICACIONES BASE DE DATOS (ANEXO E)
                                </label>
                            </div>

                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso2')} type="checkbox" className="w-4 h-4" />
                                </div>
                                <label className="text-xs">
                                    SOLICITUD DE CLAVES DE ACCESO (ANEXO F)
                                </label>
                            </div>

                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso3')} type="checkbox" className="w-4 h-4" />
                                </div>
                                <label className="text-xs">
                                    SOLICITUD DE RCE (ANEXO H)
                                </label>
                            </div>

                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso4')} type="checkbox" className="w-4 h-4" />
                                </div>
                                <label className="text-xs">
                                    RESPUESTAS DE ACLARACIONES (ANEXO I)
                                </label>
                            </div>

                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso5')} type="checkbox" className="w-4 h-4" />
                                </div>

                                <label className="text-xs">
                                    RESPONSABLE DE AUDITORÍA (ANEXO J).
                                </label>
                            </div>

                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso6')} type="checkbox" className="w-4 h-4" />
                                </div>
                                <label className="text-xs">
                                    OFICIAL DE CUMPLIMIENTO (PLD CHECK)
                                </label>
                            </div>

                            <div className='flex gap-x-6'>
                                <div className='w-6'>
                                    <input {...register('permiso7')} type="checkbox" className="w-4 h-4" />
                                </div>
                                <label className="text-xs">
                                    ALTA DE NUEVOS PRODUCTOS
                                </label>
                            </div>

                        </div>
                    </div>

                    <div className="action-container--end">
                        <button className="button--primary">
                            <img src="/img/icons/ico-iniciar-10.png" alt="Ícono" className="button__icon" />
                            Guardar
                        </button>
                    </div>
                </form>
                <Footer/>
            </div>
        </>
    )
}

export default PublicWorkers;
