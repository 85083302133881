import React from 'react';
import { Img } from 'react-image';

const PowersImage = () => {
    return (
        <Img
            src="/img/actapoderes.jpg"
            alt="Ejemplo de poderes legales"
            className="help-pill__image"
            loader={<span>Cargando imagen...</span>}
            unloader={<span>Error al cargar la imagen</span>}
        />
    );
};

export default PowersImage;
