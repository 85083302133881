import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import PublicWorkers from './content/PublicWorkers';
import LegalRepresentative from './content/LegalRepresentative';
import User from './content/User';

import ToastNotifier from '../config/toastNotifier';
import Footer from './Footer';

const TokenFailed = ({message}) => {
    return (<>
    <div className='notification'>
        <div className='notification__content'>
            <div className='notification__icon-wrapper bg-red'>
                <svg xmlns='http://www.w3.org/2000/svg' className='notification__icon feather feather-x-circle' viewBox='0 0 24 24' fill='none' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
                    <circle cx='12' cy='12' r='10'></circle>
                    <line x1='15' y1='9' x2='9' y2='15'></line>
                    <line x1='9' y1='9' x2='15' y2='15'></line>
                </svg>
            </div>
            <p className='notification__text'>{message}</p>
        </div>
    </div>
    <Footer/>
    </>)
}

const Success = () =>{
    return (<>
        <div className='notification'>
            <div className='notification__content'>
                <div className='notification__icon-wrapper bg-green-500'>
                    <svg xmlns='http://www.w3.org/2000/svg' className='notification__icon feather feather-check-circle' viewBox='0 0 24 24' fill='none' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' >
                        <path d='M9 11l3 3L22 4'></path>
                        <path d='M22 12A10 10 0 1 1 12 2a10 10 0 0 1 10 10z'></path>
                    </svg>
                </div>
                <p className='notification__text'>Hemos enviado tu solicitud, te notificaremos por correo electrónico para dar el siguiente paso.</p>
            </div>
        </div>
        <Footer/>
    </>)
}

const MainContainer = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const op = params.get('op');

    const [toastParams, setToastParams] = useState({ type: '', message: '' });
    const [operation, setOperation] = useState(0);
    //const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (token) {
            try {
                const decoded = jwtDecode(token);

                const currentTime = Math.floor(Date.now() / 1000);
                if (decoded.exp && decoded.exp < currentTime) {
                    setOperation(5);
                    setErrorMessage('Tu solicitud ha expirado.');
                    return;
                }
                // 1 Users, 2 PublicWorkers, 3 RL
                const validOperations = [1, 2, 3];
                if (validOperations.includes(Number(op))) {
                    setOperation(Number(op));
                    sessionStorage.setItem('token', token);
                } else {
                    document.getElementById('main-navbar').style.display = 'none';
                    setOperation(5);
                    setErrorMessage('La solicitud no es válida. Por favor, confirma que tu acceso sea válido');
                }
            } catch (ex) {
                document.getElementById('main-navbar').style.display = 'none';
                setOperation(5);
                setErrorMessage('El token es inválido.');
            }
        } else {
            document.getElementById('main-navbar').style.display = 'none';
            setOperation(5);
            setErrorMessage('Para continuar, es necesario verificar tu acceso.');
        }
    }, [token, op]);

    const toastNotifier = (toast) => {
        setToastParams(toast);
        setTimeout(() => {
            setToastParams({ type: '', message: '' });
        }, 0);
        //console.log(toast);
    }

    const handleSubmission = (data, toast) => {
        if (typeof data === 'string') {
            setOperation(4);
            setToastParams(toast);
        } else {
            console.log('El formato de datos recibido no es válido');
        }
    };

    const contentInit = () => {
        switch (operation) {
            case 1:
                return <User onSubmit={handleSubmission} toastNotifier={toastNotifier} />;
            case 2:
                return <PublicWorkers onSubmit={handleSubmission} toastNotifier={toastNotifier} />;
            case 3:
                return <LegalRepresentative onSubmit={handleSubmission} toastNotifier={toastNotifier} />;
            case 4:
                return <Success/>;
            case 5:
                return <TokenFailed message={errorMessage}/>;
            default:
                return <div>No existe un formulario</div>;
        }
    }

    return (
        <div>
            {contentInit()}
            <ToastNotifier type={toastParams.type} message={toastParams.message} />
        </div>
    );

};

export default MainContainer;
