import React from 'react';
import { Img } from 'react-image';

const NavBar = () => {
    return (
        <nav className="navbar navbar--main" id="main-navbar">
            <div className="navbar__logo">
                <Img src="/logo-blanco.png" alt="Logo_navbar" className="navbar__logo-image" />
            </div>
            <div className="navbar__user" id="myName">
                
            </div>
        </nav>
    );
};

export default NavBar;
