import { useState } from "react";
import IdentificationImage from '../../components/content/images/IdentificationImage';
import PowersImage from '../../components/content/images/PowersImage';

function PreviewDocument({isIne}) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };


    return (<>
        <button type="button" onClick={openModal} className="bg-skyBlue rounded-full h-6 w-6 text-white cursor-pointer">
            ?
        </button>
        {isModalOpen && <div className="modal-overlay">
            <div className="modal-content bg-white w-[600px] mx-4 md:mx-32">
                {isIne ? <IdentificationImage /> : <PowersImage />}
                <button type="button" onClick={closeModal} className="bg-skyBlue text-white font-medium border rounded-lg p-2 text-[16px]">Cerrar</button>
            </div>
        </div>}
    </>)
}

export default PreviewDocument;