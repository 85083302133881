
function SelectCustom({register, errors, isValid, keyInput, label, placeholder, data}) {
    return (
        <div className="mb-2 w-full">
            <label className="text-sm" htmlFor={keyInput}>{label}</label>
            <div className="relative w-full">
                <select 
                    {...register(keyInput)} 
                    id={keyInput} 
                    name={keyInput}
                    className={`select__input--rounded
                        ${errors[keyInput] ? 'select__input--error' : isValid ? 'select__input--valid ' : 'select__input--neutral'}`} >
                        <option className="text-gray-400" value="">{placeholder}</option>
                            {
                                data.map((e, index) => (
                                    <option key={index} value={e}>{e}</option>
                                ))
                            }
                </select>
                <div className="select__icon">
                    <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5.25 7.75l4.25 4.25 4.25-4.25" />
                    </svg>
                </div>
            </div>
            {errors[keyInput] && <small className='text--error'>*{errors[keyInput].message}</small>}
        </div>
    )
}

export default SelectCustom;