import React from 'react';
import { Img } from 'react-image';

const Footer = () => {
    return (
        <footer className="footer footer--center">
            <Img src="/logo-original-azul.svg" alt="Logo_footer" 
                loader={<img src="/img/placeholder.svg" alt="footer" className="footer__logo footer__logo--loading" />} 
                className="footer__logo" 
            />
        </footer>
    );
};

export default Footer;
